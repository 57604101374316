import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from '../../../service/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        if (this.authService.token && this.authService.angularRoutes && this.authService.angularRoutes.length > 0) {
            //  verifica dreptul la module.
            if (
                state.url !== '/' &&
                state.url !== '/suspended' &&
                !this.authService.userModulesOptions.hasOwnProperty(state.url.split('/')[1])
            ) {
                this.authService.logout();
                return false;
            }

            // User is suspended
            if (
                this.authService.isSuspended  &&  state.url !== '/suspended' &&
                (
                    (state.url !== '/accounting' && state.url !== '/roadtax' && this.authService.suspendedReason === this.authService.BAD_PAYER_REASON) ||
                    (this.authService.suspendedReason !== this.authService.BAD_PAYER_REASON)
                )
            ) {
                if ((this.authService.angularRoutes.indexOf('/accounting') > -1 || this.authService.angularRoutes.indexOf('/roadtax') > -1) && this.authService.suspendedReason === this.authService.BAD_PAYER_REASON) {
                    this.router.navigate(['/accounting']);
                    return false;
                } else {
                    this.router.navigate(['/suspended']);
                    return false;
                }
            }

            if ( 
                this.authService.isSuspended  && state.url === '/roadtax' && 
                (this.authService.userModulesOptions.roadtax.indexOf('cargobox') === -1 || (this.authService.userModulesOptions.roadtax.indexOf('cargobox') > -1 && this.authService.hasCargoboxPrepay))
            ) {
                this.router.navigate(['/accounting']);
                return false;
            }

            return true;
        }

        this.authService.logout();
        return false;

    }
}
